<template>
	<b-row>
		<b-col>
			<b-card title="Orders" class="border-0">
				<store-image-and-name :store="store" />
				<!-- List of Orders -->
				<template v-if="orders.length > 0">
					<!-- <b-card-group columns> -->
					<b-row>
						<b-col cols="12" sm="6" lg="4" v-for="order of orders" :key="order.id">
							<order-summary-card :order="order" :hideStoreDetails="true" @detailsClicked="detailsClicked" class="mb-3" />
						</b-col>
					</b-row>
					<!-- </b-card-group> -->

					<!-- Pagination -->
					<div>
						<b-pagination v-model="currentPage" :total-rows="count" :per-page="perPageCount" @change="onPaginationChange" align="center"></b-pagination>
					</div>
				</template>

				<!-- No Orders -->
				<template v-else>
					<b-alert show variant="info" class="text-center mt-5">
						<h4 class="alert-heading">No Orders Placed!</h4>
						<p>You haven't placed any order yet.</p>
					</b-alert>
				</template>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StoreImageAndName from "../../../components/store/StoreImageAndName.vue";
import OrderSummaryCard from "../../../components/orders/OrderSummaryCard";

export default {
	components: {
		OrderSummaryCard,
		StoreImageAndName,
	},
	props: ["store"],
	created() {
		this.initList();
	},
	data() {
		return {
			// for pagination
			currentPage: 1,
			perPageCount: 12,
			count: 0,
		};
	},
	computed: {
		...mapGetters({
			orders: "user/orders/getOrders",
		}),
		paginationParams() {
			const storeIds = this.store.id;
			const page = this.currentPage - 1;
			const size = this.perPageCount;
			const sort = "id,desc"; // for sorting by id desc
			return { storeIds, page, size, sort };
		},
	},
	methods: {
		...mapActions({
			getOrdersCount: "user/orders/getOrdersCount",
			getOrdersApi: "user/orders/getOrders",
		}),
		initList() {
			this.getOrdersCount(this.paginationParams).then((data) => {
				const count = data.count;
				this.count = count;
				if (count) this.getOrders();
			});
		},
		getOrders() {
			this.getOrdersApi(this.paginationParams);
		},
		onPaginationChange(page) {
			// first changing the current page
			this.currentPage = page;
			// calling order
			this.getOrders();
		},

		detailsClicked(order) {
			this.$router.push({
				name: "StoreOrderDetail",
				params: { orderId: order.id },
			});
		},
	},
};
</script>
